import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { isEmpty, size, get, map } from 'lodash'

// atoms
import TextInputField from '../../../atoms/TextInputField'
import ReasonSelector from '../../../atoms/generics/ReasonSelector'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { containsDiacritics, isEmail } from '../../../utils/email'
import { formatFormValue } from '../../../utils/form'
import { DEFINICIA_TYP } from '../../../utils/enums'

const NESUHLASY_PATH = 'data.nesuhlasy'

class KontaktnyEmailField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired
	}

	constructor(props) {
		super(props)
	}

	_mounted = false

	componentDidMount() {
		this._mounted = true
	}

	validate = (email, allValues) => {
		const { povinny, t } = this.props

		const nesuhlasyFormValue = get(allValues, NESUHLASY_PATH, null)
		let nesuhlasValueId = nesuhlasyFormValue?.[DEFINICIA_TYP.KONTAKTY_EMAIL]?.id ?? null

		if (isEmpty(email) && nesuhlasValueId === null) {
			return t('components:GenericFields.Vyplňte email alebo vyberte dôvod nesúhlasu')
		}
		if (isEmpty(email) && nesuhlasValueId !== null) {
			return
		}

		if (!isEmail(email)) {
			return t('components:GenericFields.Email nie je platný')
		}

		if (containsDiacritics(email)) {
			return t('components:GenericFields.Email nesmie obsahovať diakritiku')
		}

		if (size(email) > 2048) {
			return t('components:GenericFields.Email môže mať najviac 2048 znakov')
		}
	}

	handleChangeNesuhlas = (value) => {
		const { dispatch, change, formValues } = this.props

		const nesuhlasy = get(formValues, NESUHLASY_PATH, {})
		const changedNesuhlasy = { ...nesuhlasy, [DEFINICIA_TYP.KONTAKTY_EMAIL]: value?.nesuhlasDovod ?? undefined }

		dispatch(change(NESUHLASY_PATH, changedNesuhlasy))
	}

	handleChangeEmail = (value) => {
		const { dispatch, change, formValues, cesta } = this.props

		// Ak vyplní email tak, zmažeme nesúhlas
		const nesuhlasy = get(formValues, NESUHLASY_PATH, {})
		if (nesuhlasy[DEFINICIA_TYP.KONTAKTY_EMAIL] != undefined) {
			const changedNesuhlasy = { ...nesuhlasy, [DEFINICIA_TYP.KONTAKTY_EMAIL]: undefined }
			dispatch(change(NESUHLASY_PATH, changedNesuhlasy))
		}

		dispatch(change(cesta, value))
	}

	handleAddEfakturaButton = (efakturaEmail) => {
		const { dispatch, change, formValues } = this.props

		const opEmailEfaktury = get(formValues, 'data.opEmailEfaktury', [])
		dispatch(change('data.opEmailEfaktury', [...opEmailEfaktury, efakturaEmail]))
	}

	render() {
		const { value, error, originalValue, nesuhlasyDefinicie, formValues, cesta, editMode, columnsCount = COLUMNS_COUNT.THREE, t } = this.props

		/* 
		TODO: Premyslieť lepšie riešenie
		Ak je array namiesto null, tak viem, že AttrOPEmailEfakturaDTO je aktívne 
		*/
		const opEmailEfaktury = get(formValues, 'data.opEmailEfaktury', null)
		/*	
		Možnosť označiť len v prípade, že je vyplnený email,
		zároveň je na vstupe medzi žiadanými atribútmi v ponuke AttrOPEmailEfakturaDTO
		a zároveň zatiaľ nie je v zozname emailov v atribúte „E-faktúra“.
		Po jeho odkliknutí sa prenesie hodnota medzi hodnoty emailov v poli
		*/
		const showEfakturaButton = value && error == undefined && Array.isArray(opEmailEfaktury) && !opEmailEfaktury.includes(value)

		const attrNesuhlasy = nesuhlasyDefinicie?.find((definicia) => definicia.id === DEFINICIA_TYP.KONTAKTY_EMAIL)?.nesuhlasy
		const reasonOptions = map(attrNesuhlasy, (nesuhlas) => {
			return {
				value: nesuhlas.id,
				label: nesuhlas.dovod
			}
		})

		const nesuhlasyFormValue = get(formValues, NESUHLASY_PATH, null)
		let nesuhlasValueId = nesuhlasyFormValue?.[DEFINICIA_TYP.KONTAKTY_EMAIL]?.id ?? null

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('components:GenericFields.Kontaktný email')}</strong>
							</td>
							{(editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) && (
								<>
									{columnsCount === COLUMNS_COUNT.THREE && <td>{originalValue ?? '-'}</td>}
									<td>
										<Field
											name={cesta}
											component={TextInputField}
											placeholder={t('components:GenericFields.Zadajte kontaktný email')}
											validate={this.validate}
											input={{
												value,
												onChange: (e) => {
													this.handleChangeEmail(e.target.value)
												}
											}}
										/>
										{!value && (
											<div style={{ marginTop: '10px' }}>
												<ReasonSelector value={nesuhlasValueId} selectOptions={reasonOptions} onChange={this.handleChangeNesuhlas} />
											</div>
										)}
										{showEfakturaButton && (
											<button
												style={{ marginTop: '10px' }}
												className='button small noselect'
												type='button'
												data-type='outline'
												data-color='blue'
												onClick={() => this.handleAddEfakturaButton(value)}
											>
												{t('components:GenericFields.Aktivovať e-faktúru')}
											</button>
										)}
									</td>
								</>
							)}
							{(editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) && (
								<>
									{columnsCount === COLUMNS_COUNT.THREE && <td>{originalValue ?? '-'}</td>}
									<td>{formatFormValue(value, originalValue) || '-'}</td>
								</>
							)}
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapDispatchToProps))(KontaktnyEmailField)
