import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { Tooltip } from 'react-tippy'

// atoms
import CheckboxField from '../../../atoms/CheckboxField'

// components
import AddressField from '../../../atoms/NewAddressFields/AddressField'

import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatAddress } from '../../../utils/address'

class KorespondencnaAdresaField extends React.Component {
	static propTypes = {
		t: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired,
		editMode: PropTypes.string.isRequired,
		field: PropTypes.string.isRequired,
		value: PropTypes.shape().isRequired,
		originalValue: PropTypes.shape(),
		columnsCount: PropTypes.string,
		nastavAdresyZU: PropTypes.array
	}

	constructor(props) {
		super(props)

		this.state = {}
	}

	componentDidMount() {
		this._mounted = true

		const { dispatch, change, editMode, nastavAdresyZU } = this.props

		if (editMode === EDIT_MODE.CREATE || editMode === EDIT_MODE.EDIT) {
			const shouldSetNastavAdresyZU = Array.isArray(nastavAdresyZU) && nastavAdresyZU.length > 0
			dispatch(change('data.adresaKorespondencnaZmena.nastavAdresyZU', shouldSetNastavAdresyZU))
		}
	}

	validate = (value) => {
		const { t, povinny } = this.props

		if (povinny && !value) {
			return t('components:GenericFields.Korešpondenčná adresa je povinná')
		}
	}

	checkIfAddressesAreEqual = (address1, address2) => {
		if (address1?.id === address2?.id) {
			return true
		}

		return false
	}

	render() {
		const { t, field, value, originalValue, columnsCount, editMode, adresyPosta, nastavAdresyZU } = this.props

		const valueBefore = <td>{formatAddress(originalValue)}</td>
		let valueAfter = null
		const isAddressEqual = this.checkIfAddressesAreEqual(originalValue, value)

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP || editMode == EDIT_MODE.CREATE) {
			valueAfter = (
				<td>
					<Field name={field} create edit component={AddressField} addresses={adresyPosta} validate={this.validate} />
					<div style={{ display: 'flex', gap: '5px', alignItems: 'center', marginTop: '10px' }}>
						<Field
							name={`data.adresaKorespondencnaZmena.nastavAdresyZU`}
							component={CheckboxField}
							label={t('components:GenericFields.Nastav adresy ZU')}
							disabled={!Array.isArray(nastavAdresyZU) || nastavAdresyZU?.length === 0}
						/>
						<Tooltip
							html={
								<span>
									{t(
										'components:GenericFields.Zvolená adresa bude nastavená ako adresa pre poštovú komunikáciu a zároveň ako fakturačná adresa na všetkých zmluvných účtoch'
									)}
								</span>
							}
							position='bottom'
							trigger='mouseenter'
							theme='light'
						>
							<div className='info-icon' />
						</Tooltip>
					</div>
				</td>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = (
				<td style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
					<div className='input-wrapper'>{isAddressEqual ? formatAddress(value) : <strong>{formatAddress(value) || '-'}</strong>}</div>
				</td>
			)
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('components:GenericFields.Korešpondenčná adresa')}</strong>
							</td>
							{columnsCount === COLUMNS_COUNT.THREE && valueBefore}
							{valueAfter}
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	addresses: state.formAddresses.data
})

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(mapStateToProps, mapDispatchToProps))(KorespondencnaAdresaField)
